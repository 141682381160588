
































































































import { Component, Vue, Watch, Inject } from "vue-property-decorator";
import debounce from "lodash.debounce";

import ScreenResultsTable from "@/components/screen-results/screen-results-table.vue";
import Card from "@/components/material/Card.vue";
import { TableOptions, VDataTableOptions } from "@/models/form";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import LocalState from "@/store/modules/local-state-module";

import ScreenResultsService from "@/services/screen-results-service";
import { ScreenMetadata } from "../models/screen-result.d";

import { getDateFromDateTime } from "../formatters/datetime";
import axios, { CancelTokenSource } from "axios";

import { Query } from "@/models/types"
import { formatSort } from "@/components/helper/sortHelpers"

const appState = getModule(AppState);
const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    ScreenResultsTable
  }
})
export default class ScreenResultsListView extends Vue {
  @Inject() ScreenResultsService!: ScreenResultsService;

  options: TableOptions = {
    link: true
  };

  hitsOnly = true;

  snackbar = {
    value: false,
    message: "",
    color: ""
  };

  items: ScreenMetadata[] = [];

  loading = false;

  page = 1;

  numberOfPages = 1;

  size = localState.defaultPageSize;

  sort: string[] = ["screening-date,desc"];

  filter: string | null = null;

  newFilter: string | null = null;

  fromDate?: string = "";

  toDate?: string = "";

  whenFilter = false;

  WhenFromDate = false;

  WhenFromTime = false;

  WhenToDate = false;

  WhenToTime = false;

  cancellationToken!: CancelTokenSource;

  get color() {
    return appState.apiFault ? "error" : "success";
  }

  get apiFault() {
    return appState.apiFault;
  }

  get sortBy() {
    return this.sort.map(x => x?.split(",")[0]);
  }

  get sortDesc() {
    return this.sort.map(x => x?.split(",")[1] === "desc");
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    const { page = 1, size = this.size, sort = this.sort, filter = "", from = "", to = "" } =
      this.$route.query || {};

    this.page = +page;
    this.size = +size;
    this.sort = formatSort(sort);
    this.filter = `${filter}`;

    const fromDateTime = new Date(`${from ?? "01-01-0000T00:00Z"}`);
    this.fromDate = from ? getDateFromDateTime(fromDateTime) : undefined;

    const toDateTime = new Date(`${to ?? "01-01-0000T00:00Z"}`);
    this.toDate = to ? getDateFromDateTime(toDateTime) : undefined;

    this.loadPage();
  }

  private debounceFilter?: () => void;

  @Watch("filter")
  onFilterChange() {
    if (!this.debounceFilter) {
      this.debounceFilter = debounce(() => {
        const currentQuery = this.$route.query;
        const { filter } = currentQuery;

        if (filter === this.filter) return;

        this.$router.replace({
          query: {
            ...currentQuery,
            page: "1",
            filter: this.filter && this.filter.length ? this.filter : undefined
          }
        });
      }, 500);
    }

    if (this.debounceFilter) {
      this.debounceFilter();
    }
  }

  reset() {
    this.hitsOnly = true;
    this.fromDate = undefined;
    this.toDate = undefined;

    const currentQuery = this.$route.query;

    this.$router.replace({
      query: {
        ...currentQuery,
        from: undefined,
        to: undefined
      }
    });
  }

  formatFromDateTime(): string {
    return `${this.fromDate ?? "0001-01-01"}T00:00Z`;
  }

  formatToDateTime(): string {
    return `${this.toDate ?? "0001-01-01"}T00:00Z`;
  }

  fromChanged() {
    const currentQuery = this.$route.query;
    const { from } = currentQuery;
    this.WhenFromDate = false;

    const fromDateTime = this.formatFromDateTime();
    if (from === fromDateTime) return;

    this.$router.replace({
      query: {
        ...currentQuery,
        from: this.fromDate ? fromDateTime : undefined
      }
    });
  }

  toChanged() {
    const currentQuery = this.$route.query;
    const { to } = currentQuery;
    this.WhenToDate = false;

    const toDateTime = this.formatToDateTime();
    if (to === toDateTime) return;

    this.$router.replace({
      query: {
        ...currentQuery,
        to: this.toDate ? toDateTime : undefined
      }
    });
  }

  optionsUpdated(options: VDataTableOptions) {
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    const sort = sortBy.map((by, index) => `${by},${sortDesc[index] ? "desc" : "asc"}`);

    const fromDateTime = this.formatFromDateTime();
    const toDateTime = this.formatToDateTime();
    const currentQuery = this.$route.query;
    const newQuery: Partial<Query> = {
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort,
      filter: this.filter,
      from: this.fromDate ? fromDateTime : "",
      to: this.toDate ? toDateTime : ""
    };
    if (sort.length === 0) delete newQuery.sort;
    if (!this.filter) delete newQuery.filter;
    if (!this.fromDate) delete newQuery.from;
    if (!this.toDate) delete newQuery.to;

    if (JSON.stringify(currentQuery) === JSON.stringify(newQuery)) return;

    this.$router.push({
      query: newQuery
    });
  }

  async loadPage() {
    this.loading = true;

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort
    };

    const fromDate = this.formatFromDateTime();
    const toDate = this.formatToDateTime();

    try {
      if (this.cancellationToken) this.cancellationToken.cancel("Reloaded list");
      this.cancellationToken = axios.CancelToken.source();

      if (fromDate != "" && fromDate != null && this.filter !== "" && this.filter != null) {
        const list = await this.ScreenResultsService.listScreenResults(
          pagination,
          this.filter || undefined,
          this.fromDate !== undefined ? new Date(fromDate) : undefined,
          this.toDate !== undefined ? new Date(toDate) : undefined
        );

        this.size = list.page.size;
        this.page = list.page.number;
        this.items = list._embedded["screen-results"];

        localState.setDefaultPageSize(this.size);
      } else {
        this.loading = false;
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbar.message = error as string;
        this.snackbar.color = "error";
        this.snackbar.value = true;
      }
    } finally {
      this.loading = false;
    }
  }
}
