



































































import {
  Component, Vue, Prop, PropSync,
} from 'vue-property-decorator';

import { TableHeader } from '@/components/layout/models/table.d';
import { TableOptions } from '@/models/form';

import config from '@/config';

@Component
export default class ScreenResultsTable extends Vue {
  @Prop({ default: () => ({}) }) options!: TableOptions;

  @Prop({ default: false }) apiFault!: boolean;

  @PropSync('itemsPerPage', { default: () => config.defaultPageSize }) syncedItemsPerPage!: number;

  @PropSync('page', { default: 1 }) syncedPage!: number;

  @PropSync('filter', { default: '' }) syncedFilter!: string;

  @PropSync('status', { default: 5 }) syncedStatus!: number;

  headers: TableHeader[] = [];

  get numberOfPages() {
    return this.syncedPage + 1;
  }

  hasHandler(name: string): boolean {
    return name in this.$listeners;
  }

  mounted() {
    this.headers = [
      {
        text: 'Screening Id',
        value: 'screening-id',
        sortable: false,
      },
      {
        text: 'Screening Date',
        value: 'screening-date',
        sortable: true,
        sortDirection: 'desc',
      },
      {
        text: 'Info',
        value: 'correlation-data',
        sortable: false,
      },
    ];
  }
}
